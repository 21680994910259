import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const getCKUAData = async () => {
  let api = apiUrl ?? '';

  try {
    const res = await axios.get(api);
    return res.data as CKUADataDTO;
  } catch (error) {
    console.error('Error fetching CKUA data:', error);
    throw error;
  }
};

export default getCKUAData;
